@import "assets/linear/style";

// BOOTSTRAP 4 VARIABLES

@import "assets/components/bootstrap4/functions";
@import "assets/components/bootstrap4/variables";
@import "assets/components/bootstrap4/mixins";

// ArchitectUI Themes Variables

@import "assets/themes/layout-variables";

// BOOTSTRAP 4 IMPORTS

@import "assets/components/bootstrap4/root";
@import "assets/components/bootstrap4/reboot";
@import "assets/components/bootstrap4/type";
@import "assets/components/bootstrap4/images";
@import "assets/components/bootstrap4/code";
@import "assets/components/bootstrap4/grid";
@import "assets/components/bootstrap4/tables";
@import "assets/components/bootstrap4/buttons";
@import "assets/components/bootstrap4/transitions";
@import "assets/components/bootstrap4/dropdown";
@import "assets/components/bootstrap4/button-group";
@import "assets/components/bootstrap4/input-group";
@import "assets/components/bootstrap4/custom-forms";
@import "assets/components/bootstrap4/nav";
@import "assets/components/bootstrap4/navbar";
@import "assets/components/bootstrap4/card";
@import "assets/components/bootstrap4/breadcrumb";
@import "assets/components/bootstrap4/pagination";
@import "assets/components/bootstrap4/badge";
@import "assets/components/bootstrap4/jumbotron";
@import "assets/components/bootstrap4/alert";
@import "assets/components/bootstrap4/progress";
@import "assets/components/bootstrap4/media";
@import "assets/components/bootstrap4/list-group";
@import "assets/components/bootstrap4/close";
@import "assets/components/bootstrap4/toasts";
@import "assets/components/bootstrap4/tooltip";
@import "assets/components/bootstrap4/popover";
@import "assets/components/bootstrap4/carousel";
@import "assets/components/bootstrap4/utilities";
// LAYOUT

@import "assets/layout/layout";

// UTILS

@import "assets/utils/helpers";
@import "assets/utils/backgrounds";
@import "assets/utils/animate";
@import "assets/utils/comps-animations";

// ELEMENTS

@import "assets/elements/buttons";
@import "assets/elements/dropdown";
@import "assets/elements/navs";
@import "assets/elements/badges";
@import "assets/elements/cards";
@import "assets/elements/tabs";
@import "assets/elements/loaders";
@import "assets/elements/progressbar";
@import "assets/elements/timeline";
@import "assets/elements/listgroup";
@import "assets/elements/forms";
@import "assets/elements/pagination";
@import "assets/elements/chat";

// DASHBOARD BOXES

@import "assets/widgets/chart-boxes/chart-boxes";
@import "assets/widgets/content-boxes/content-boxes";
@import "assets/widgets/profile-boxes/profile-boxes";

// PAGES

// Applications

@import "assets/applications/applications-base";

// Perfect Scrollbar

// RC Tabs
@import "assets/components/rc-tabs/rc-tabs";

// Hamburger Button
@import "assets/components/hamburgers/hamburgers";

// Drawer
@import "assets/components/drawer/drawer";

// Notifications
@import "assets/components/notifications/sweetalerts";
@import "assets/components/notifications/toastify";

// Modals
@import "assets/components/modals/rodal";

// Progress Bar

// Slick Carousel

// Block loading

// Popovers & Tooltips
@import "assets/components/popovers-tooltips/popovers-tooltips";

// DEMO

@import "assets/demo-ui/demo";

// Responsive
@import "assets/layout/responsive/responsive-base";

@import "App.css";

.bgimg1 {
	opacity: 0;
}
.bgimg2 {
	opacity: 0;
}
.bgimg3 {
	opacity: 0;
}

img {
	transition: opacity 1s linear;
}

.card-header {
	font-size: medium;
}
.card {
	font-size: medium;
}
div.app-footer {
	z-index: 200;
	position: absolute;
}
.maptxt {
	font-weight: bolder;
	font-size: medium;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: black;
	border-color: gray;
	border-radius: 5px;
	text-align: left;
	box-shadow: gray;
}

@media screen and (max-width: 495px) {
	div.MobileAct {
		display: none;
	}
}
@media screen and (max-width: 400px) {
	div.app-header-left {
		display: none;
	}
}
@media screen and (max-width: 400px) {
	div.search-wrapper {
		display: none;
	}
}
@media screen and (max-width: 334) {
	div.header-btn-lg {
		display: none;
	}
}

.ponoTabBG {
	background: radial-gradient(
		rgba(235, 235, 235, 0.911),
		rgba(255, 255, 255, 0.836)
	);
}
.ponoTitle {
	justify-content: center;
}

.zoom {
	transition: transform 0.2s; /* Animation */
}

.zoom:hover {
	transform: scale(
		1.2
	); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.centerInput {
	position: relative;
	justify-content: center;
	text-align: center;
}
@media screen and (max-width: 350px) {
	.headerInfo2 {
		left: 46%;
	}
}
@media screen and(min-width: 200px) and (max-width: 550px) {
	.centerButton {
		position: relative;
		left: 6%;
	}
	.headerInfo1 {
		display: none;
	}
	.headerInfo2 {
		left: 50%;
		transform: translateX(-15px);
	}
	.logo-src {
		position: relative;
	}
}
@media screen and(min-width: 550px) and (max-width: 650px) {
	.centerButton {
		position: relative;
		left: 7%;
	}
	.headerInfo2 {
		left: 46%;
	}

	.headerInfo1 {
		position: relative;
		transform: translateX(-5px);
	}
}
@media screen and(min-width: 650px) and (max-width: 750px) {
	.centerButton {
		position: relative;
		left: 12%;
	}

	.headerInfo1 {
		position: relative;
		transform: translateX(50px);
	}
}
.headerInfo2 {
	left: 46%;
	position: relative;
}

@media screen and (max-width: 825px) and (min-width: 750px) {
	.centerButton {
		position: relative;
		left: 12%;
	}

	.headerInfo2 {
		left: 46%;
		position: relative;
	}
	.headerInfo1 {
		position: relative;
		transform: translateX(50px);
	}
}

@media screen and (min-width: 825px) and (max-width: 991px) {
	.centerButton {
		position: relative;
		left: 12%;
	}
	.headerInfo2 {
		left: 46%;
		position: relative;
	}
	.headerInfo1 {
		position: relative;
		transform: translateX(50px);
	}
}

@media screen and (min-width: 992px) and (max-width: 1266px) {
	.centerButton {
		position: relative;
		left: 12%;
	}

	.headerInfo2 {
		left: 7px;
		position: absolute;
	}
	.headerInfo1 {
		position: relative;
		transform: translateX(50px);
	}
	#footer {
		position: relative;
		transform: translateX(50px);
	}
}

@media screen and (min-width: 1267px) and (max-width: 1750px) {
	.centerButton {
		position: relative;
		left: 6%;
	}
	.headerInfo2 {
		left: 105px;
		position: absolute;
	}
	.headerInfo1 {
		position: relative;
		transform: translateX(250px);
	}
	#footer {
		position: relative;
		transform: translateX(100px);
	}
}

@media screen and (min-width: 1750px) and (max-width: 1950px) {
	.headerInfo1 {
		position: relative;
		transform: translateX(450px);
	}
	.centerButton {
		position: relative;
		left: 6%;
	}
	.headerInfo2 {
		left: 105px;
		position: absolute;
	}

	#footer {
		position: relative;
		transform: translateX(150px);
	}
}

h3 {
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
}
h4 {
	font-family: "Montserrat", sans-serif;
}
p {
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
}
@media screen and (max-width: 1200px) {
	h3 {
		font-size: 22px;
		font-family: "Montserrat", sans-serif;
	}
}

.card-body {
	justify-content: center;
}

.landingContent {
	background-image: linear-gradient(
		180deg,
		#1c238033 20%,
		#05045aff 100%
	) !important;
	width: 100%;
	height: 100%;
	position: absolute;
	align-self: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	background-color: transparent;
	transition: 3500ms;
	animation: fadeIn 3s ease-in;
}
.spin {
	opacity: 0.9;
	animation: rotato 60s linear infinite;
}

.landingPin {
	position: absolute;
	left: 50%;
	top: 37%;
	transform: translate(-50%, -50%);
}

.landingMaui {
	position: absolute;
	top: 30%;
}

@media screen and (max-width: 500px) {
	.AccountBackground {
		width: 105%;
	}
}

@media screen and (max-width: 400px) and (min-width: 300px) {
	.ModeratorBackground {
		width: 107%;
		position: relative;
		left: -11px;
	}
}

@media screen and (max-width: 452px) and (min-width: 400px) {
	.ModeratorBackground {
		width: 107%;
		position: relative;
		left: -14px;
	}
}
@media screen and (max-width: 475px) and (min-width: 452px) {
	.ModeratorBackground {
		width: 107%;
		position: relative;
		left: -17px;
	}
}
@media screen and (max-width: 492px) and (min-width: 475px) {
	.ModeratorBackground {
		width: 106%;
		position: relative;
		left: -15px;
	}
}

@media screen and (min-width: 600px) {
	.AccountBackground {
		width: 102%;
	}
	.ModeratorBackground {
		width: 100%;
	}
}
@keyframes rotato {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(-0%) rotate(360deg);
	}
}
@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-ms-keyframes spin {
	from {
		-ms-transform: rotate(0deg);
	}
	to {
		-ms-transform: rotate(360deg);
	}
}
@-moz-keyframes spin {
	from {
		-moz-transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

h1 {
	font-weight: bolder;

	font-family: "Montserrat", sans-serif;
}
h2 {
	font-weight: bolder;

	font-family: "Montserrat", sans-serif;
}
#hiddenText {
	margin: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
#visibleText {
	margin: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

.CarouselCard img {
	max-width: 300px;
	max-height: 500px;
}

.CarouselCard {
	height: 400px;
	max-height: 500px;
	max-width: 300px;
}

.colJustText {
	width: 100%;
	text-align: right;
	align-items: center;
}
/* Always set the map height explicitly to define the size of the div
       * element that contains the map. */
#map {
	height: 100%;
}

/* Optional: Makes the sample page fill the window. */
html,
body {
	height: 100%;
	width: 100%;
	display: block;
	margin: 0;
	padding: 0;
}
.footer {
	position: absolute;
	bottom: 0;
	display: block;
	width: 100%;
}

#description {
	font-family: Roboto;
	font-size: 15px;
	font-weight: 300;
}

#infowindow-content .title {
	font-weight: bold;
}

#infowindow-content {
	display: none;
}

#map #infowindow-content {
	display: inline;
}

.pac-card {
	margin: 10px 0 0 0;
	border-radius: 2px 0 0 2px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	outline: none;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
	background-color: #fff;
	font-family: Roboto;
	position: "relative";
	right: 15px;
	margin-right: 15px;
}

#pac-container {
	padding-bottom: 12px;
	margin-right: 12px;
}

.pac-controls {
	display: "center";
	padding: 5px 11px;
}

.pac-controls label {
	font-family: Roboto;
	font-size: 13px;
	font-weight: 300;
}

#pac-input {
	background-color: #fff;
	font-family: Roboto;
	font-size: 15px;
	font-weight: 300;
	margin-left: 12px;
	padding: 0 11px 0 13px;
	text-overflow: ellipsis;
	width: 400px;
}

#pac-input:focus {
	border-color: #4d90fe;
}

#title {
	color: #fff;
	background-color: #4d90fe;
	font-size: 25px;
	font-weight: 500;
	padding: 6px 12px;
}

#root
	> div
	> div.app-main
	> div.app-sidebar.sidebar-shadow
	> div.scrollbar-container.ps
	> div.app-sidebar__inner {
	font-size: 24px;
	font-family: "Courier New", Courier, monospace;
	font-weight: bold;
}

.img1 {
	animation: var(--animation, none) 16s infinite linear;
	align-self: flex-end;
	--direction: 50%;
	animation-name: "slide";
}

@keyframes slide {
	to {
		-webkit-transform: translateX(var(--direction, -50%));
		transform: translateX(var(--direction, -50%));
	}
}

.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
	-webkit-animation-duration: 300ms;
	animation-duration: 300ms;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	75% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

input[type="radio"] {
	visibility: visible;
	width: 20px;
	height: 20px;
	margin: 0px 0px 0px 2px;
	position: relative;
	top: 4px;
	visibility: visible;
}
input[type="radio"]:checked:after {
	position: relative;
	display: block;
	visibility: visible;
	border-radius: 9px;
	z-index: 1;
	padding-bottom: 21px;
	background: radial-gradient(
		ellipse at top left,
		rgb(27, 88, 200) 100%,
		rgb(27, 88, 200) 100%,
		rgb(27, 88, 200) 100%,
		rgb(27, 88, 200) 100%
	);
	content: "";
}

input[type="checkbox"]:disabled {
	width: 20px;
	height: 20px;
	position: relative;
	visibility: visible;
	display: inline-block;
}
input[type="checkbox"] {
	visibility: visible;
	width: 20px;
	height: 20px;
	margin: 0px 0px 0px 2px;
	position: relative;
	top: 4px;
	visibility: visible;
}

.tooltip2 {
	position: relative;
	display: inline-block;
}

.tooltip2 .tooltip2text {
	visibility: hidden;
	width: 140px;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	bottom: 150%;
	left: 50%;
	margin-left: -75px;
	opacity: 0;
	transition: opacity 0.3s;
}

.tooltip2 .tooltip2text::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
}

.tooltip2:hover .tooltip2text {
	visibility: visible;
	opacity: 1;
}
.PayPalButtonBackground {
	background-image: linear-gradient(
		180deg,
		rgb(153, 151, 184),
		rgba(12, 6, 47, 0.959)
	);
}

input[type="checkbox"]:checked:after {
	position: relative;
	display: block;
	visibility: visible;
	z-index: 1;
	padding-bottom: 21px;
	background: rgb(27, 88, 200) 100%;

	content: "";
}
input[type="checkbox"]:disabled {
	width: 20px;
	height: 20px;
	position: relative;
	visibility: visible;
	display: inline-block;
}
.listingExample img {
	max-height: 250px;
	max-width: 100%;
	object-fit: cover;
}

#footer {
	position: fixed;
	z-index: 9;
	bottom: 0px;
	width: 100%;
	height: 70px;
	left: 0px;
	background: linear-gradient(
		#0f98a700,
		#0f97a7,
		#0f97a7 50%,
		#0f97a7 50%,
		#041a49
	);
	color: black;
}

#root
	> div
	> div.app-main
	> div.app-main__outer
	> div
	> div
	> div
	> div
	> div
	> div
	> div
	> div.tab-pane.active
	> div
	> div
	> div
	> div
	> div
	> div
	> div
	> div
	> p
	> img {
	max-width: 350px;
}

#root
	> div
	> div.app-header.header-shadow
	> div.app-header__content
	> div.app-header-right
	> div.header-btn-lg.pr-0
	> div
	> div
	> div:nth-child(1) {
	position: relative;
	top: -4px;
}
#root
	> div
	> div.app-main
	> div.app-sidebar.sidebar-shadow
	> div.app-header__logo {
	display: none;
}

#particle-js {
	width: 100%;
	height: 100%;
	background: transparent;
	transition-duration: 1s;
	transition: opacity 2s;
}

.metricsClass {
	box-decoration-break: slice;
	-webkit-box-decoration-break: slice;
	box-shadow: 8px 8px 10px 0 #ff1493, -5px -5px 5px 0 #00f, 5px 5px 15px 0 #ff0;
}

.gradientBtn {
	border-radius: 10px;
	display: inline-block;
	white-space: nowrap;
	transition: 1s;
	background: transparent;
	color: black;
	text-align: left;
}
.spin2 {
	animation: roto 10s linear infinite;
}

.button {
	text-decoration: none;
}
#root
	> div
	> div.app-header.header-shadow
	> div.app-header__mobile-menu
	> div
	> button {
	text-decoration: none;
	background-color: transparent;
}

#root
	> div
	> div.app-header.header-shadow
	> div.app-header__logo
	> div
	> div
	> button {
	text-decoration: none;
	background-color: transparent;
}

@keyframes roto {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(-0%) rotate(360deg);
	}
}
.gradientBtn:hover {
	transition: 1s;
	background-image: linear-gradient(
		to right,
		#00d2ff 100%,
		#3a7bd5 51%,
		#00d2ff 0%
	);
	background-position: right center;
}

.sidebarLinks {
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
	margin-bottom: 25px;
}
#root
	> div
	> div.app-main
	> div.app-sidebar.sidebar-shadow
	> div.scrollbar-container.ps
	> div.app-sidebar__inner
	> a {
	margin-bottom: 45px;
	margin-top: 45px;
}
.metismenu-item {
	margin-bottom: 45px;
	margin-top: 45px;
}

.HoverToTeal:hover {
	color: #159696;
}

.HoverToTeal a:link {
	color: #003377;
}

.HoverToTeal a:visited {
	color: #003377;
}

.HoverToTeal a:active {
	color: #003377;
}

.HoverToTeal {
	color: #003377;
}
#root
	> div
	> div.app-main
	> div.app-main__outer
	> div
	> div:nth-child(1)
	> div:nth-child(5)
	> div
	> div
	> span
	> div
	> div
	> button:nth-child(1) {
	z-index: 3;
}
#root
	> div
	> div.app-main
	> div.app-main__outer
	> div
	> div:nth-child(1)
	> div:nth-child(5)
	> div
	> div
	> span
	> div
	> div
	> button:nth-child(3) {
	z-index: 3;
}

button {
	height: 36px;
	border-radius: 2px;
	text-align: center;
	color: $white;
	background: #005bd3;
	border: 0;
	font-weight: 400;
	padding: 0 16px;
	font-family: inherit;
	font-size: inherit;
	cursor: pointer;
	&:hover {
		background: lighten(#005bd3, 10%);
	}
	&:focus {
		outline: none;
	}
	&:active {
		background: lighten(#005bd3, 15%);
	}
}

.app-sidebar__heading {
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
}

#AccountHeaderButton {
	transition: opacity 1s;
}
